import React, { lazy, Suspense, useState } from "react";

import { useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AntdModal from "./components/antdModal/AntdModal";
import PageLoading from "./components/loadings/PageLoading";
import { routes } from "./core/route/router";
import { REACT_APP_GOOGLE_MAPS_API_KEY } from "./core/constance/ApiKeys";
import { LoadScript } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";


const ClassicModal = lazy(() => import('./components/modal/ClassicModal'))
const Toasty = lazy(() => import('./components/toasty/Toasty'))
const ConstanceSettings = lazy(() => import('./containers/pages/constance_settings/ConstanceSettings'))
const Containers = lazy(() => import('./containers/pages/containers/Containers'))
const Deneme = lazy(() => import('./containers/pages/containers/Deneme'))
const DeviceManagement = lazy(() => import('./containers/pages/device_management/DeviceManagement'))
const DeviceSettings = lazy(() => import('./containers/pages/device_settings/DeviceSettings'))
const HomePage = lazy(() => import('./containers/pages/home_page/HomePage'))
const Login = lazy(() => import('./containers/pages/login/Login'))
const Maps = lazy(() => import('./containers/pages/maps/Maps2'))
const MapRouteManagement = lazy(() => import('./containers/pages/map_route_management/MapRouteManagement'))
const RouteControl = lazy(() => import('./containers/pages/map_route_management/RouteControl'))
const Notifications = lazy(() => import('./containers/pages/notifications/Notifications'))
const NotFound = lazy(() => import('./containers/pages/not_found/NotFound'))
const DeviceBoxOperation = lazy(() => import('./containers/pages/operations/DeviceBoxOperation'))
const DeviceQrOperation = lazy(() => import('./containers/pages/operations/DeviceQrOperation'))
const DeviceShipment = lazy(() => import('./containers/pages/operations/DeviceShipment'))
const DeviceTest = lazy(() => import('./containers/pages/operations/DeviceTest'))
const ShippingCustomerManagement = lazy(() => import('./containers/pages/operations/ShippingCustomerManagement'))
const ShippingManagement = lazy(() => import('./containers/pages/operations/ShippingManagement'))
const PageStructure = lazy(() => import('./containers/pages/page_structure/PageStructure'))
const Permissions = lazy(() => import('./containers/pages/permissions/Permissions'))
const Planning = lazy(() => import('./containers/pages/planning/Planning'))
const AddPlan = lazy(() => import('./containers/pages/planning/planning_operations/AddPlan'))
const SortPlan = lazy(() => import('./containers/pages/planning/planning_operations/SortPlan'))
const UpdatePlan = lazy(() => import('./containers/pages/planning/planning_operations/UpdatePlan'))
const Settings = lazy(() => import('./containers/pages/settings/Settings'))
const Setup = lazy(() => import('./containers/pages/setup/Setup'))
const SystemManagement = lazy(() => import('./containers/pages/system_management/SystemManagement'))
const TabletDriver = lazy(() => import('./containers/pages/tablet_driver/TabletDriver'))
const UserManagemenet = lazy(() => import('./containers/pages/user_managemenet/UserManagemenet'))
const Database = lazy(() => import('./containers/pages/database/Database'))
const Modifications = lazy(() => import('./containers/pages/modifications/Modifications'))
const ChauffeurMonitoring = lazy(() => import('./containers/pages/chauffeur_monitoring/ChauffeurMonitoring2'))
const ResetPassword = lazy(() => import('./containers/pages/reset_password/ResetPassword'))
const SearchTool = lazy(() => import('./containers/pages/search_tool/SearchTool2'))
const ReportsAndGrraps = lazy(() => import('./containers/pages/reports_and_graphs/ReportsAndGrraps2'))
const AppVersion = lazy(() => import('./containers/pages/app_version/AppVersion'))


const library = ["places"];


function App() {
  //! TRANSLATE
  const { i18n } = useTranslation();
  const [isTokenExist] = useState(localStorage.getItem('token') ? true : false)
  const [isUserInfoExist] = useState(localStorage.getItem('user_info') ? true : false)
  const [isCompanyExist] = useState(localStorage.getItem('company') ? true : false)
  const [isGlobalInfoExist] = useState(localStorage.getItem('globalInfo') ? true : false)

  const themeController = useSelector(state => state.themeController)
  const company = useSelector(state => state.authController.company)

  try {

    return (
      <LoadScript
        googleMapsApiKey={REACT_APP_GOOGLE_MAPS_API_KEY}
        language={i18n.language} // Set the language to 'tr' for Turkish
        libraries={library}
      >
        <div className={`App ${themeController.themeIsDark == true ? 'dark-theme' : 'light-theme'}`}>
          <Suspense fallback={<div style={{ width: '100vw', height: '100vh', margin: 'auto' }}><PageLoading /></div>}>
            <AntdModal />
            <ClassicModal />
            <ToastContainer />
            <Toasty />
            <Routes>
              {
                !isUserInfoExist || !isCompanyExist || !isGlobalInfoExist || !isTokenExist ?
                  <>
                    <Route path={routes.login.path} element={<Login />} />
                    <Route path={routes.password_reset.path} element={<ResetPassword />} />
                  </>
                  :
                  <>
                    {company.company_pocket.permissions.HOME_PAGE.display === true && <Route exact path={routes.home_page.path} element={<PageStructure page={<HomePage />} />} />}
                    {company.company_pocket.permissions.MAPS_ROUTE_MANAGEMENT_PAGE.display === true && <Route path={routes.map_route_managemenet.path} element={<PageStructure page={<MapRouteManagement />} />} />}
                    {company.company_pocket.permissions.MAPS_ROUTE_MANAGEMENT_PAGE.display === true && <Route path={`${routes.map_route_managemenet.check_route.path}/duty`} element={<PageStructure page={<RouteControl />} />} />}
                    {company.company_pocket.permissions.MAPS_ROUTE_MANAGEMENT_PAGE.display === true && <Route path={`${routes.map_route_managemenet.check_route.path}/duty/:duty_id`} element={<PageStructure page={<RouteControl />} />} />}
                    {company.company_pocket.permissions.MAPS_PAGE.display === true && <Route path={routes.maps.path} element={<PageStructure page={<Maps />} />} />}
                    {/* {company.company_pocket.permissions.DRIVER_MONITORING_PAGE.display === true && <Route path={routes.chauffeur_monitoring.path} element={<PageStructure page={<ChauffeurMonitoring />} />} />} */}
                    {company.company_pocket.permissions.DRIVER_MONITORING_PAGE.display === true && <Route path={routes.chauffeur_monitoring.path} element={<ChauffeurMonitoring />} />}
                    {company.company_pocket.permissions.CONTAINERS_PAGE.display === true && <Route path={routes.container.path} element={<PageStructure page={<Containers />} />} />}
                    {/* {company.company_pocket.permissions.MAPS_ROUTE_MANAGEMENT_PAGE.display === true && <Route path={routes.deneme.path} element={<PageStructure page={<Deneme />} />} />} */}
                    {company.company_pocket.permissions.PLANNING_PAGE.display === true && <Route path={routes.planning.path} element={<PageStructure page={<Planning />} />} />}
                    {company.company_pocket.permissions.PLANNING_PAGE.features.ADD_PLAN.display === true && <Route path={routes.planning.add.path} element={<PageStructure page={<AddPlan />} />} />}
                    {company.company_pocket.permissions.PLANNING_PAGE.features.UPDATE_PLAN.display === true && <Route path={routes.planning.updata.path} element={<PageStructure page={<UpdatePlan />} />} />}
                    {company.company_pocket.permissions.PLANNING_PAGE.features.SORT_PLAN.display === true && <Route path={routes.planning.sort.path} element={<PageStructure page={<SortPlan />} />} />}
                    {company.company_pocket.permissions.NOTIFICATION_PAGE.display === true && <Route path={routes.notification.path} element={<PageStructure page={<Notifications />} />} />}
                    {company.company_pocket.permissions.USER_MANAGEMENT_PAGE.display === true && <Route path={routes.user_managemenet.path} element={<PageStructure page={<UserManagemenet />} />} />}
                    {company.company_pocket.permissions.SETTINGS_PAGE.display === true && <Route path={routes.settings.path} element={<PageStructure page={<Settings />} />} />}
                    {company.company_pocket.permissions.DEFAULT_SETTINGS_PAGE.display === true && <Route path={routes.constances_settings.path} element={<PageStructure page={<ConstanceSettings />} />} />}
                    {/* {company.company_pocket.permissions.MAPS_ROUTE_MANAGEMENT_PAGE.display === true && <Route path={routes.tablet_driver.path} element={<PageStructure page={<TabletDriver />} />} />} */}
                    {company.company_pocket.permissions.SETUP_PAGE.display === true && <Route path={routes.setup.path} element={<PageStructure page={<Setup />} />} />}
                    {/* {company.company_pocket.permissions.DEVICE_MANAGEMENT_PAGE.display === true && <Route path={routes.report.path} element={<PageStructure page={<ReportsAndGrraps />} />} />} */}
                    <Route path={routes.report.path} element={<PageStructure page={<ReportsAndGrraps />} />} />
                    {company.company_pocket.permissions.DEVICE_MANAGEMENT_PAGE.display === true && <Route path={routes.device_management.path} element={<PageStructure page={<DeviceManagement />} />} />}
                    {company.company_pocket.permissions.DEVICE_SETTINGS_PAGE.display === true && <Route path={routes.device_settings.path} element={<PageStructure page={<DeviceSettings />} />} />}
                    {company.company_pocket.permissions.DEVICE_MODIFICATION_PAGE.display === true && <Route path={routes.modifications.path} element={<PageStructure page={<Modifications />} />} />}
                    {company.company_pocket.permissions.SYSTEM_MANAGEMENT_PAGE.display === true && <Route path={routes.system_management.path} element={<PageStructure page={<SystemManagement />} />} />}
                    {company.company_pocket.permissions.SYSTEM_MANAGEMENT_PAGE.display === true && <Route path={routes.search_tool.path} element={<PageStructure page={<SearchTool />} />} />}
                    {company.company_pocket.permissions.HOME_PAGE.display === true && <Route path={routes.device_qr_operation.path} element={<PageStructure page={<DeviceQrOperation />} />} />}
                    {company.company_pocket.permissions.HOME_PAGE.display === true && <Route path={routes.device_box_qr_operation.path} element={<PageStructure page={<DeviceBoxOperation />} />} />}
                    {company.company_pocket.permissions.HOME_PAGE.display === true && <Route path={routes.device_box_shipment.path} element={<PageStructure page={<DeviceShipment />} />} />}
                    {company.company_pocket.permissions.HOME_PAGE.display === true && <Route path={routes.shipping_customer_managemenet.path} element={<PageStructure page={<ShippingCustomerManagement />} />} />}
                    {company.company_pocket.permissions.HOME_PAGE.display === true && <Route path={routes.shipping_managemenet.path} element={<PageStructure page={<ShippingManagement />} />} />}
                    {company.company_pocket.permissions.HOME_PAGE.display === true && <Route path={routes.device_test.path} element={<PageStructure page={<DeviceTest />} />} />}
                    {company.company_pocket.permissions.HOME_PAGE.display === true && <Route path={routes.permissions.path} element={<PageStructure page={<Permissions />} />} />}
                    {company.company_pocket.permissions.HOME_PAGE.display === true && <Route path={routes.app_version.path} element={<PageStructure page={<AppVersion />} />} />}


                    {/* {permissions.HOME_PAGE.display === true && <Route path={routes.database.path} element={<PageStructure page={<Database />} />} />} */}
                    <Route path={routes.password_reset.path} element={<ResetPassword />} />
                    <Route path={routes.notFound.path} element={<NotFound />} />
                  </>
              }
              <Route path={routes.notFound.path} element={<Navigate to={routes.login.path} />} />
            </Routes>
          </Suspense>
        </div>
      </LoadScript>
    );

  } catch (error) {
    if (error) {
      console.log("error : ", error)
      localStorage.clear()
    }
  }
}


export default App;
